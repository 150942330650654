import React, { useEffect, useState } from 'react';
import storeData from './StoreData.json'; // Import existing data
import Modal from 'react-modal';

Modal.setAppElement('#root');

const StoreTable = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        region: '',
        brandName: '',
        storeName: '',
        doors: '',
        media: '',
        location: '',
    });
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        setData(storeData);
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = [...data].sort((a, b) => {
        if (!sortConfig.key) return 0;

        const aValue = a[sortConfig.key] || '';
        const bValue = b[sortConfig.key] || '';

        if (sortConfig.direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        return (
            (!filters.region || item.Region.toLowerCase().includes(filters.region.toLowerCase())) &&
            (!filters.brandName || item["Brand Name "].toLowerCase().includes(filters.brandName.toLowerCase())) &&
            (!filters.storeName || item.StoreName.toLowerCase().includes(filters.storeName.toLowerCase())) &&
            (!filters.doors || item.Doors.toLowerCase().includes(filters.doors.toLowerCase())) &&
            (!filters.media || item.Media.toLowerCase().includes(filters.media.toLowerCase())) &&
            (!filters.location || item.Location.toLowerCase().includes(filters.location.toLowerCase()))
        );
    });

    const openImageDialog = (image) => {
        setSelectedImage(image);
    };

    const closeImageDialog = () => {
        setSelectedImage(null);
    };

    return (
        <div>
            <h2>Store Data</h2>

            {/* Filter Section */}
            <div style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Filter by Region"
                    name="region"
                    value={filters.region}
                    onChange={handleFilterChange}
                />
                <input
                    type="text"
                    placeholder="Filter by Brand Name"
                    name="brandName"
                    value={filters.brandName}
                    onChange={handleFilterChange}
                />
                <input
                    type="text"
                    placeholder="Filter by Assest Element Name"
                    name="storeName"
                    value={filters.storeName}
                    onChange={handleFilterChange}
                />
                <input
                    type="text"
                    placeholder="Filter by Doors"
                    name="doors"
                    value={filters.doors}
                    onChange={handleFilterChange}
                />
                <input
                    type="text"
                    placeholder="Filter by Media"
                    name="media"
                    value={filters.media}
                    onChange={handleFilterChange}
                />
                <input
                    type="text"
                    placeholder="Filter by Location"
                    name="location"
                    value={filters.location}
                    onChange={handleFilterChange}
                />
            </div>

            {/* Table Section */}
            <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
    <tr>
        {[
            'SR. No',
            'Region',
            'Brand Name ',
            'Assest Element Name',
            'Doors',
            'Visible Width mm',
            'Visible Height mm',
            'Print size (Width mm)',
            'Print size (Height mm)',
            'QTY',
            'Media',
            'Location',
            'Price',
            'Reece Completion Date',
            'Visual Change Date',
            'Images',
        ].map((col) => (
            <th
                key={col}
                style={{
                    border: '1px solid black',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap', // Keep text in a single line
                    overflow: 'hidden', // Prevent overflow
                    textOverflow: 'ellipsis', // Add ellipsis if text is too long
                    padding: '8px 16px', // Add spacing for readability
                }}
                onClick={() => handleSort(col)}
            >
                {col} {sortConfig.key === col ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
            </th>
        ))}
    </tr>
</thead>

                    <tbody>
                        {filteredData.map((item, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid black' }}>{item["SR. No"]}</td>
                                <td style={{ border: '1px solid black' }}>{item.Region}</td>
                                <td style={{ border: '1px solid black' }}>{item["Brand Name "]}</td>
                                <td style={{ border: '1px solid black' }}>{item.StoreName}</td>
                                <td style={{ border: '1px solid black' }}>{item.Doors}</td>
                                <td style={{ border: '1px solid black' }}>{item["Width mm"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Height mm"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Print size (Width mm)"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Print size (Height mm)"]}</td>
                                <td style={{ border: '1px solid black' }}>{item.QTY}</td>
                                <td style={{ border: '1px solid black' }}>{item.Media}</td>
                                <td style={{ border: '1px solid black' }}>{item.Location}</td>
                                <td style={{ border: '1px solid black' }}>{item.Price}</td>
                                <td style={{ border: '1px solid black' }}>{item["Reece Completion Date"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Visual Change Date"]}</td>
                                <td style={{ border: '1px solid black' }}>
                                    {item.Images ? (
                                        <img
                                            src={item.Images}
                                            alt="Image"
                                            style={{ width: '50px', cursor: 'pointer' }}
                                            onClick={() => openImageDialog(item.Images)}
                                        />
                                    ) : (
                                        <span>No image</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Viewing Image */}
            {selectedImage && (
                <Modal isOpen={!!selectedImage} onRequestClose={closeImageDialog}>
                    <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
                    <button onClick={closeImageDialog}>Close</button>
                </Modal>
            )}
        </div>
    );
};

export default StoreTable;
