import React, { useEffect, useState } from 'react';
import storeData from './StoreData.json'; // Import existing data
import Modal from 'react-modal';

Modal.setAppElement('#root');

const StoreTable = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        region: [],
        brandName: [],
        storeName: [],
        doors: [],
        media: [],
        location: [],
    });
    const [selectedFilters, setSelectedFilters] = useState({
        region: [],
        brandName: [],
        storeName: [],
        doors: [],
        media: [],
        location: [],
    });
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [selectedImage, setSelectedImage] = useState(null);
    const [filterVisibility, setFilterVisibility] = useState({
        region: false,
        brandName: false,
        storeName: false,
        doors: false,
        media: false,
        location: false,
    });

    // Update filters after data is loaded
    useEffect(() => {
        if (storeData.length > 0) {
            setData(storeData); // Set the data
            setFilters({
                region: getUniqueValues('Region'),
                brandName: getUniqueValues('Brand name'),
                storeName: getUniqueValues('StoreName'),
                doors: getUniqueValues('Doors'),
                media: getUniqueValues('Media'),
                location: getUniqueValues('Location'),
            });
        }
    }, []);

    // Helper function to get unique values for each filter
    const getUniqueValues = (field) => {
        // Extract unique values based on field name
        const uniqueValues = [...new Set(storeData.map((item) => item[field]))].filter((value) => value);
        return uniqueValues;
    };

    const handleCheckboxChange = (e, field) => {
        const { value, checked } = e.target;

        setSelectedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            if (checked) {
                updatedFilters[field].push(value);
            } else {
                updatedFilters[field] = updatedFilters[field].filter((v) => v !== value);
            }
            return updatedFilters;
        });
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on the selected sort config
    const sortedData = [...data].sort((a, b) => {
        if (!sortConfig.key) return 0;

        const aValue = a[sortConfig.key] || '';
        const bValue = b[sortConfig.key] || '';

        if (sortConfig.direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    // Filter data based on selected filters
    const filteredData = sortedData.filter((item) => {
        return (
            (!selectedFilters.region.length || selectedFilters.region.includes(item.Region)) &&
            (!selectedFilters.brandName.length || selectedFilters.brandName.includes(item["Brand Name "])) &&
            (!selectedFilters.storeName.length || selectedFilters.storeName.includes(item.StoreName)) &&
            (!selectedFilters.doors.length || selectedFilters.doors.includes(item.Doors)) &&
            (!selectedFilters.media.length || selectedFilters.media.includes(item.Media)) &&
            (!selectedFilters.location.length || selectedFilters.location.includes(item.Location))
        );
    });

    const openImageDialog = (image) => {
        setSelectedImage(image);
    };

    const closeImageDialog = () => {
        setSelectedImage(null);
    };

    const toggleFilterVisibility = (field) => {
        setFilterVisibility((prevVisibility) => ({
            ...prevVisibility,
            [field]: !prevVisibility[field],
        }));
    };

    return (
        <div>
            <h2>Store Data</h2>

            {/* Filter Section - Horizontal */}
            <div style={{
                marginBottom: '100px', 
                display: 'flex', 
                flexWrap: 'wrap', 
                marginTop: '20px', 
                gap: '20px', 
                justifyContent: 'center'
            }}>
                {['region', 'brandName', 'storeName', 'doors', 'media', 'location'].map((field) => (
                    <div key={field} style={{ position: 'relative', width: '200px' }}>
                        <button 
                            onClick={() => toggleFilterVisibility(field)} 
                            style={{
                                width: '100%', 
                                padding: '10px', 
                                backgroundColor: '#007bff', 
                                color: '#fff', 
                                border: 'none', 
                                borderRadius: '5px', 
                                cursor: 'pointer', 
                                textAlign: 'left',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {field.charAt(0).toUpperCase() + field.slice(1)}
                            <span style={{ fontSize: '14px' }}>{filterVisibility[field] ? '▲' : '▼'}</span>
                        </button>
                        {filterVisibility[field] && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50px',
                                    left: '0',
                                    backgroundColor: '#fff',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    zIndex: 1,
                                    width: '100%',
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                {filters[field].map((value) => (
                                    <label key={value} style={{ display: 'block', marginBottom: '5px' }}>
                                        <input
                                            type="checkbox"
                                            value={value}
                                            checked={selectedFilters[field].includes(value)}
                                            onChange={(e) => handleCheckboxChange(e, field)}
                                            style={{ marginRight: '10px' }}
                                        />
                                        {value}
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Table Section */}
            <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr>
                            {[
                                'SR. No',
                                'Region',
                                'Brand Name',
                                'Assest Element Name',
                                'Doors',
                                'Visible Width mm',
                                'Visible Height mm',
                                'Print size (Width mm)',
                                'Print size (Height mm)',
                                'QTY',
                                'Media',
                                'Location',
                                'Price',
                                'Reece Completion Date',
                                'Visual Change Date',
                                'Images',
                            ].map((col) => (
                                <th
                                    key={col}
                                    style={{
                                        border: '1px solid black',
                                        cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '8px 16px',
                                    }}
                                    onClick={() => handleSort(col)}
                                >
                                    {col} {sortConfig.key === col ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {filteredData.map((item, index) => (
                            <tr key={index}>
                                {console.log(item)}
                                <td style={{ border: '1px solid black' }}>{item["SR. No"]}</td>
                                <td style={{ border: '1px solid black' }}>{item.Region}</td>
                                <td style={{ border: '1px solid black' }}>{item["Brand name"]}</td>
                                <td style={{ border: '1px solid black' }}>{item.StoreName}</td>
                                <td style={{ border: '1px solid black' }}>{item.Doors}</td>
                                <td style={{ border: '1px solid black' }}>{item["Width mm"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Height mm"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Print size (Width mm)"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Print size (Height mm)"]}</td>
                                <td style={{ border: '1px solid black' }}>{item.QTY}</td>
                                <td style={{ border: '1px solid black' }}>{item.Media}</td>
                                <td style={{ border: '1px solid black' }}>{item.Location}</td>
                                <td style={{ border: '1px solid black' }}>{item.Price}</td>
                                <td style={{ border: '1px solid black' }}>{item["Reece Completion Date"]}</td>
                                <td style={{ border: '1px solid black' }}>{item["Visual Change Date"]}</td>
                                <td style={{ border: '1px solid black' }}>
                                    {item.Images ? (
                                        <img
                                            src={item.Images}
                                            alt="Image"
                                            style={{ width: '50px', cursor: 'pointer' }}
                                            onClick={() => openImageDialog(item.Images)}
                                        />
                                    ) : (
                                        <span>No image</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Viewing Image */}
            {selectedImage && (
                <Modal isOpen={!!selectedImage} onRequestClose={closeImageDialog}>
                    <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
                    <button onClick={closeImageDialog}>Close</button>
                </Modal>
            )}
        </div>
    );
};

export default StoreTable;
